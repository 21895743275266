import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Hero from '../components/hero'

const NotFoundPage = () => {
    return (
        <>
            <GatsbySeo title="404 - Sidan finns inte." />
            <Hero
                heading="404 - Sidan finns inte."
                body="Du har kommit till en sida som inte finns. Tråkigt men sant!"
            />
        </>
    )
}

export default NotFoundPage
